import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/auth-provider';
import { logOut } from '../../function-utils/log-out';

export const ChangePlanPage = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth(); // Assuming useAuth provides setUser

  const handleUpgrade = () => {
    navigate('/select-plan');
  };

  const handleLogout = async () => {
    await logOut(setUser, navigate);
  };

  return (
    <div>
      <h1>Your Trial Has Expired</h1>
      <p>To continue using our services, please upgrade your plan.</p>
      <button onClick={handleUpgrade}>Upgrade Plan</button>
      <button onClick={handleLogout} style={{ marginLeft: '10px' }}>
        Logout
      </button>
    </div>
  );
};
