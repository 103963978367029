import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth-provider';

const useCheckTrialPlan = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const trialPlanId = process.env.REACT_APP_TRIAL_PLAN_ID;
    const unprotectedRoutes = [
      '/',
      '/login',
      '/register-user',
      '/register-company',
      '/job-board',
    ];

    console.log('Trial Plan ID:', trialPlanId);
    console.log('User:', user);

    // Allow access to unprotected routes
    if (unprotectedRoutes.includes(location.pathname)) {
      return;
    }

    if (!trialPlanId) {
      console.log('Trial Plan ID is not defined in environment variables.');

      return;
    }
    if (!user) {
      console.log('User data is not yet available.');

      return;
    }

    if (user.subscription_plan_id === parseInt(trialPlanId)) {
      const accountCreationDate = new Date(user.createdAt);
      const currentDate = new Date();

      const timeDifference =
        currentDate.getTime() - accountCreationDate.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      console.log('Days Difference:', daysDifference);

      if (daysDifference > 7) {
        console.log('Trial period expired. Redirecting to Change Plan page.');
        navigate('/change-plan'); // Redirect to the new Change Plan page
      } else {
        console.log(
          `Trial period still active. ${
            7 - Math.floor(daysDifference)
          } days left.`
        );
      }
    } else {
      console.log('User is not on the trial plan.');
    }
  }, [user, navigate, location]);

  return null;
};

export default useCheckTrialPlan;
