import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth-provider';
import { LogIn } from '../login/login';
import { SignUpStep2 } from '../login/sign-up-step-2';
import { MyCompany } from '../pages/home/home';
import { JobOffers } from '../pages/jobs/job-offers';
import { Candidates } from '../pages/applicants/candidates/candidates';
import { TimeAndAttendanceDashboard } from '../pages/time-and-attendance/dashboard';
import { TeamManagement } from '../pages/time-and-attendance/team-management';
import { EmailVerification } from '../pages/email-verification/email-verification';
import { EditJobOffer } from '../pages/jobs/edit-job-offer';
import { RegisterUser } from '../register/register-user';
import { RegisterCompany } from '../register/register-company';
import { Employees } from '../pages/employees/employees';
import { CandidateProfile } from '../pages/applicants/candidate-profile/candidate-profile';
import { SelectedJobProvider } from '../../context/selected-job-context';
import { AddAdmin } from '../pages/add-admin/add-admin';
import { CompanyJobBoard } from '../pages/company-job-board/company-job-board';
import { InterviewConfirmation } from '../pages/interview-confirmation/interview-confirmation';
import { JobOfferConfirmation } from '../pages/interview-confirmation/job-offer-confirmation';
import { Employee } from '../pages/employees/employee';
import { EditEmployee } from '../pages/employees/edit-employee';
import { InterviewsToday } from '../pages/interviews/interviews-today/interviews-today';
import { HotCandidates } from '../pages/applicants/hot-candidates/hot-candidates';
import { CreateJobOfferFlow } from '../pages/jobs/create-job-offer/create-job-offer-flow';
import { TestPage } from '../pages/test-stripe/test-stripe';
import { TestPaypal } from '../pages/test-paypal/test-paypal';
import { SharedApplyForm } from '../pages/job-board/shared-apply-form';
import SettingsMain from '../pages/settings/settings-main';
import {
  AddEmployee,
  NewEmployeeDataTypes,
} from '../pages/employees/add-employee';
import EmployeesTimeoff from '../pages/employees/employees-timeoff-table/employees-timeoff';
import { SuperAdminHome } from '../pages/super-admin/home';
import { SuperAdminSettings } from '../pages/super-admin/settings';
import { SuperAdminTab } from '../pages/super-admin/super-admin-main/super-admin';
import AdminCompanyInfo from '../pages/super-admin/company-info/admin-company-info';
import { CompaniesMain } from '../pages/super-admin/companies/companies-main';
import NotificationTesting from '../../notification-testing';
import { RejectedCandidates } from '../pages/applicants/candidates/rejected-candidates/rejected-candidates';
import { LandingPage } from '../../landing-page/landing-page';
import { JobPostMobile } from '../pages/company-job-board/job-post-mobile';
import { ForgotPassword } from '../register/forgot-password';
import { SetNewPassword } from '../register/set-new-password';
import { PrivacyPolicy } from '../../landing-page/pages/privacy-policy/privacy-policy';
import { TermsOfUse } from '../../landing-page/pages/terms-of-use/terms-of-use';
import { SubscriptionPlansPage } from '../pages/super-admin/subscription-plans/subscription-plans-main';
import { Footer } from '../footer/footer';
import useCheckTrialPlan from '../hooks/use-check-trial';
import { ChangePlanPage } from '../pages/change-plan';

export const AppRouter = () => {
  const { user, cookies } = useAuth();
  const [editableJobOfferData, setEditableJobOfferData] = useState();
  const [editableEmployeeData, setEditableEmployeeData] =
    useState<NewEmployeeDataTypes>();

  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line
  const updateEditableJobData = (jobToBeUpdated: any) => {
    setEditableJobOfferData(jobToBeUpdated);
  };

  const updateEditableEmployeeData = (
    employeeToBeUpdated: NewEmployeeDataTypes
  ) => {
    setEditableEmployeeData(employeeToBeUpdated);
  };

  useEffect(() => {
    if (
      user &&
      (window.location.pathname === '/' ||
        window.location.pathname === '/login' ||
        window.location.pathname === '/register-user' ||
        window.location.pathname === '/register-company')
    ) {
      if (user.entityType === 'company') {
        navigate(`/admin`);
      } else if (user.entityType === 'employee') {
        navigate(`/employee`);
      }
    }
  }, [user, navigate]);

  useCheckTrialPlan();

  return (
    <>
      <Routes>
        <Route path="/change-plan" element={<ChangePlanPage />} />
        <Route path="/info" element={<LandingPage />} />
        <Route path="/info/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/info/terms" element={<TermsOfUse />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword navigate={navigate} />}
        />
        <Route
          path="/set-new-password"
          element={<SetNewPassword navigate={navigate} />}
        />
        <Route path="/apply/:companyId/:jobId" element={<SharedApplyForm />} />
        <Route path="/test-notifications" element={<NotificationTesting />} />
        <Route path="/test-paypal" element={<TestPaypal />} />
        <Route path="/test-payment" element={<TestPage />} />
        <Route
          path="/job-board/mobile-job-post/:companyId/:jobId"
          element={<JobPostMobile />}
        />
        <Route
          path="/global"
          element={<CompanyJobBoard company={user} fromPage="global" />}
        />
        <Route
          path="/:companyName"
          element={<CompanyJobBoard company={user} />}
        />
        <Route
          path="/"
          element={<LogIn user={user} navigate={navigate} cookies={cookies} />}
        />
        <Route
          path="/register-company"
          element={
            <RegisterCompany
              user={user}
              navigate={navigate}
              cookies={cookies}
            />
          }
        />
        <Route
          path="/register-user"
          element={
            <RegisterUser user={user} navigate={navigate} cookies={cookies} />
          }
        />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/verify-employee-email" element={<EmailVerification />} />
        <Route path="/step-2" element={<SignUpStep2 />} />
        <Route path="/confirm-interview" element={<InterviewConfirmation />} />
        <Route path="/confirm-job-offer" element={<JobOfferConfirmation />} />
        {user && user.entityType === 'company' && (
          <>
            <Route
              path={`/admin`}
              element={
                <SelectedJobProvider>
                  <MyCompany
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/calendar`}
              element={
                <MyCompany user={user} cookies={cookies} navigate={navigate} />
              }
            />
            <Route
              path={`/:companyName/applicants/:applicantId`}
              element={
                <CandidateProfile
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path={`/:companyName/jobs/job-offers`}
              element={
                <SelectedJobProvider>
                  <JobOffers
                    company={user}
                    cookies={cookies}
                    navigate={navigate}
                    updateEditableJobData={updateEditableJobData}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/applicants`}
              element={
                <SelectedJobProvider>
                  <Candidates
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/rejected`}
              element={
                <SelectedJobProvider>
                  <RejectedCandidates
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/jobs/create-job-offer`}
              element={
                <CreateJobOfferFlow
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path={`/:companyName/jobs/edit-job-offer`}
              element={
                <EditJobOffer
                  company={user}
                  cookies={cookies}
                  editableJobOfferData={editableJobOfferData}
                />
              }
            />
            <Route
              path={`/:companyName/time-and-attendance/dashboard`}
              element={
                <TimeAndAttendanceDashboard
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path={`/:companyName/time-and-attendance/team-management`}
              element={
                <TeamManagement
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path={`/:companyName/benefits/benefits`}
              element={<SignUpStep2 />}
            />
            <Route
              path={`/:companyName/employees`}
              element={
                <Employees
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:companyName/employees/:id"
              element={
                <Employee
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                  updateEmployeeData={updateEditableEmployeeData}
                />
              }
            />
            <Route
              path="/:companyName/employees/add-employee"
              element={
                <AddEmployee
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:companyName/employees/:id/edit"
              element={
                <EditEmployee
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                  employee={editableEmployeeData}
                />
              }
            />
            <Route
              path={`/:companyName/interviews-today`}
              element={
                <SelectedJobProvider>
                  <InterviewsToday
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/hot-prospects`}
              element={
                <SelectedJobProvider>
                  <HotCandidates
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/settings`}
              element={
                <SelectedJobProvider>
                  <SettingsMain
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            <Route
              path={`/:companyName/employees/dayoff`}
              element={
                <SelectedJobProvider>
                  <EmployeesTimeoff
                    user={user}
                    cookies={cookies}
                    navigate={navigate}
                  />
                </SelectedJobProvider>
              }
            />
            {/* Admin routes */}
            <Route
              path={`/:companyName/admins`}
              element={
                <AddAdmin
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path={`/:companyName/admins/add`}
              element={
                <AddAdmin
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
          </>
        )}
        {user && user.entityType === 'employee' && (
          <>
            <Route
              path="/employee"
              element={
                <Employee
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                  updateEmployeeData={updateEditableEmployeeData}
                />
              }
            />
          </>
        )}
        {user && user.account_type === 'superAdmin' && (
          <>
            <Route
              path="/super-admin"
              element={
                <SuperAdminHome
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:id/super-admin/settings"
              element={
                <SuperAdminSettings
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:id/super-admin/admins"
              element={
                <SuperAdminTab
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:id/super-admin/super-admin"
              element={
                <AdminCompanyInfo
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:id/super-admin/subscription-plans"
              element={
                <SubscriptionPlansPage
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/:id/super-admin/companies"
              element={<CompaniesMain navigate={navigate} user={user} />}
            />
          </>
        )}
      </Routes>
      {location.pathname !== '/info' && <Footer />}
    </>
  );
};
